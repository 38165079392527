import { Avatar } from "antd";
import AxiosCustom from "apis/axiosCustom";
import { useEffect, useLayoutEffect, useState } from "react";
import { CiUser } from "react-icons/ci";
import { Outlet } from "react-router";
import { useNavigate } from "react-router-dom";
import Logo from '../assets/logo/lhufind-logo.png';
export function MainLayout() {
  const navi = useNavigate();
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [userData, setUserData] = useState<{
    displayName: string | null;
    email: string | null;
    id: string;
    avatar: string;
    userName: string;
  }>();
  const token = localStorage.getItem('lf.accessToken');
  useEffect(() => {
    if (!token) {
      navi('/auth/login');
    } else {
      (async () => {
        try {
          const data = await AxiosCustom.get("/getUser");
          setIsLogin(true);
          setUserData(data.data);
        } catch (error) {
          console.log("Token invalid");
          localStorage.removeItem("lf.accessToken");
          localStorage.removeItem("currentUser");
          navi('/auth/login');
        }
      })();
    }
  }, [])
  function handleLogout() {
    localStorage.removeItem("lf.accessToken");
    localStorage.removeItem("currentUser");
    navi('/auth/login');
  }
  return (
    <div className="d-flex flex-column" style={{ height: '100vh', overflow: 'auto' }}>
      <header className="sticky" style={{ top: 0 }}>
        <div className="container d-flex justify-content-between align-items-center" style={{ height: 40 }}>
          <div>
            <img width='auto' height={40} src={Logo} alt="" />
          </div>
          <div className="right-header d-flex">
            <div className="me-2">
              <Avatar size={38} icon={<CiUser />} src={userData?.avatar} />
            </div>
            <div className="me-5 d-flex align-items-center">
              {userData?.displayName ? userData.displayName : userData?.userName}
            </div>
            <div>
              <button className="btn btn-danger" onClick={handleLogout}>
                Logout
              </button>
            </div>
          </div>
        </div>
      </header>
      <main><Outlet /></main>
      <footer className="mt-auto">footer</footer>
    </div>
  )
}