import { GoogleOAuthProvider } from "@react-oauth/google";
import { notification } from "antd";
import React, { useMemo } from "react";
import { RouterProvider } from "react-router";
import router from "router";
const Context = React.createContext({ name: 'Default' });
function App() {
  const [api, contextHolder] = notification.useNotification();
  const contextValue = useMemo(() => ({ name: 'YC app' }), []);
  return (
    <GoogleOAuthProvider clientId="253092077863-2crd1glpc1qfhmj85hlgf4ce7nfibg4f.apps.googleusercontent.com">
      <Context.Provider value={contextValue}>
        {contextHolder}
        <RouterProvider router={router} />
      </Context.Provider>
    </GoogleOAuthProvider>
  );
}

export default App;
