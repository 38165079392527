import { Card } from "antd";
import AxiosCustom from "apis/axiosCustom";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./index.scss";
import { Major } from "ts/enum";
export default function ProfileDetail() {
  const params = useParams();
  const [profileData, setProfileData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const currentUser = JSON.parse(localStorage.getItem("currentUser") || '');
  useEffect(() => {
    setIsLoading(true);
    (async () => {
      if (params.profileId) {
        try {
          const data = await AxiosCustom.get('/findById', {
            params: {
              id: params.profileId
            }
          })
          setProfileData(data.data);
          setIsLoading(false);
          // console.log(data)
        } catch (error: any) {
          console.log(error)
        }
      }
    })();
  }, [params]);
  function getMajor(classId?: string): string {
    if (classId) {
      const mId = classId.slice(2, 4);
      return (Major as any)[mId];
    }
    return "";
  }
  return <>
    <div className="container mt-5">
      <div className="d-flex justify-content-around">
        <div className="left-side d-flex flex-column align-items-center">
          <img width={180} height={240} style={{ borderRadius: 10 }} src={currentUser?.role == 'admin' ? `https://file.lhu.edu.vn/basic/StudentImage/${profileData?.StudentID}.jpg` : "https://static-00.iconduck.com/assets.00/avatar-default-symbolic-icon-440x512-ni4kvfm4.png"} alt="profile img" />
          <div>
            <small style={{ color: "red", fontSize: 11 }}>* Only super user can see profile imgage</small>
          </div>
          <div style={{ fontWeight: 600 }}>
            {profileData?.HoTen}
          </div>
        </div>
        <div className="right-side p-3">
          <div className="d-flex justify-content-around">
            <div className="mssv me-5 w-100">
              <Card title="MSSV" bordered={false}>
                {profileData?.StudentID}
              </Card>
            </div>
            <div className="class me-5 w-100 ">
              <Card title="Class ID" bordered={false}>
                {profileData?.LopID}
              </Card>
            </div>
            <div className="gender w-100">
              <Card title="gender" bordered={false}>
                Male
              </Card>
            </div>
          </div>
          <div className="d-flex justify-content-around mt-5">
            <div className="majors me-5 w-100">
              <Card title="Majors" bordered={false}>
                {getMajor(profileData?.LopID)}
              </Card>
            </div>
            <div className="class me-5">
              <Card title="birth ID" bordered={false}>
                {profileData?.NgaySinh}
              </Card>
            </div>
          </div>
          <div className="d-flex justify-content-around mt-5">
            <div className="address">
              <Card title="Address" bordered={false}>
                {`${profileData?.HoKhau}, ${profileData?.TenPhuongXa}, ${profileData?.TenQuanHuyen}, ${profileData?.TinhThanh}`}
              </Card>
            </div>
          </div>
          <div className="d-flex justify-content-around mt-5">
            <div className="phone me-5 w-100">
              <Card title="Phone" bordered={false}>
                {profileData?.SoDienThoai}
              </Card>
            </div>
            <div className="email w-100">
              <Card title="Email" bordered={false}>
                {profileData?.Email}
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
}